import React, { useState } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import Lightbox from "../components/lightbox"
// import NextPrev from "../components/next-prev"
import SEO from "../components/seo"
import constants from "../styles/constants"

/* eslint jsx-a11y/anchor-is-valid: "off" */
/* eslint no-script-url: "off" */

const styles = {
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  article: {
    width: "63%",
  },
  aside: {
    width: "33%",
  },
  fullViewOverlay: {
    position: "absolute",
    top: 0,
    right: 0,
    padding: "4px",
    fontSize: "60%",
    textTransform: "uppercase",
    background: "rgba(0,0,0,0.6)",
    color: "#fff",
  },
}

const MoreImages = ({ images, openGalleryAt }) => {
  // the first image is "main", not rendered here
  if (!images || images.length === 1) return null

  return (
    <>
      <h4 style={{ margin: "2rem 0 0.75rem" }}>More images</h4>
      {images.map(
        (image, index) =>
          index > 0 && (
            <a
              href="javascript:;"
              onClick={() => openGalleryAt(index)}
              key={index}
            >
              <Img fixed={image.fixed} />
            </a>
          )
      )}
    </>
  )
}

const MoreInfo = ({ data }) => {
  return ["Medium", "Dimensions", "Date", "Status", "Tags"].map(detail =>
    data[detail] ? (
      <div key={detail}>
        <h5 style={{ color: constants.mediumGray, margin: "0.5rem 0 0" }}>
          {detail}
        </h5>
        <p style={{ fontSize: "75%", lineHeight: 1.5, margin: 0 }}>
          {data[detail]}
        </p>
      </div>
    ) : null
  )
}

const ArtPiecePage = props => {
  const [initialImageIndex, setInitialLightboxIndex] = useState(0)
  const [isGalleryOpen, setGalleryState] = useState(false)
  const openGalleryAt = index => {
    setInitialLightboxIndex(index)
    setGalleryState(true)
  }
  const {
    pageContext: { sectionSlug }, //, next, previous },
    data: {
      markdownRemark: { html, frontmatter },
    },
  } = props
  const { title, images: badImageMap } = frontmatter
  const images = badImageMap.map(img => img.childImageSharp)
  return (
    <Layout activeSectionSlug={sectionSlug}>
      <SEO title={title} />
      <h1>{title}</h1>
      <div style={styles.wrapper}>
        <article style={styles.article}>
          <a
            href="javascript:;"
            onClick={() => openGalleryAt(0)}
            style={{ position: "relative", display: "block" }}
          >
            <Img fluid={images[0].fluid} />
            <div style={styles.fullViewOverlay}>Click for full view</div>
          </a>
        </article>
        <aside style={styles.aside}>
          <div dangerouslySetInnerHTML={{ __html: html }} />
          <MoreImages images={images} openGalleryAt={openGalleryAt} />
          <MoreInfo data={frontmatter} />
        </aside>
      </div>
      <Lightbox
        isOpen={isGalleryOpen}
        images={images}
        onClose={() => setGalleryState(false)}
        initialImageIndex={initialImageIndex}
      />

      {/* working; just ugly: <NextPrev next={next} prev={previous} /> */}
    </Layout>
  )
}
export default ArtPiecePage

// the "fields.sectionSlug" value is assigned dynamically in gatsby-node; it's not
// part of the schema (i.e., not manually named in each MD file)
//
// the other tags in "frontmatter" are the ones available in the header of the MD files.
//
export const pageQuery = graphql`
  query GetArtPiece($slug: String) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        Medium
        Dimensions
        Date
        Status
        Tags
        images {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
            fixed(width: 100, height: 100) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
      }
    }
  }
`
