import React from "react"
import PropTypes from "prop-types"
import Carousel, { Modal, ModalGateway } from "react-images"

function Lightbox(props) {
  const { images, initialImageIndex = 0, isOpen, onClose } = props
  return (
    <ModalGateway>
      {isOpen && (
        <Modal
          onClose={onClose}
          styles={{
            blanket: base => ({
              ...base,
              backgroundColor: "rgba(16,11,0,0.95)",
              zIndex: 900,
            }),
            dialog: base => ({ ...base, width: "100%" }),
            positioner: base => ({ ...base, zIndex: 901 }),
          }}
        >
          <Carousel
            views={images.map(({ fluid }) => ({
              source: fluid.src,
            }))}
            currentIndex={initialImageIndex}
            components={{ FooterCount: () => null }}
            styles={{
              footer: base => ({
                ...base,
                background: "none !important",
                color: "#666",
                padding: 0,
                paddingTop: 20,
                position: "static",
                "& a": { color: "black" },
              }),
              header: base => ({
                ...base,
                background: "none !important",
                padding: 0,
                paddingBottom: 10,
                position: "static",
              }),
              headerClose: base => ({
                ...base,
                color: "#666",
                ":hover": { color: "#DE350B" },
              }),
              view: base => ({
                ...base,
                overflow: "hidden",
                objectFit: "contain",
                "& > img": {
                  maxHeight: "75vh",
                  height: "auto",
                  width: "auto",
                  margin: "0 auto",
                },
              }),
            }}
          />
        </Modal>
      )}
    </ModalGateway>
  )
}

Lightbox.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      fluid: PropTypes.any,
    })
  ).isRequired,
  initialImageIndex: PropTypes.number,
  onClose: PropTypes.func.isRequired,
}

export default Lightbox
